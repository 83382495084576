<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="mt-5">
      <k-field-group language-prefix="role.form.fields">
        <v-col cols="12" lg="6" md="8" offset-lg="3" offset-md="2" offset-sm="1" sm="10">
          <v-subheader class="pa-0">{{ $t(`role.form.title${isUpdateForm ? "Update" : "Add"}`) }}</v-subheader>
          <v-card>
            <v-card-text>
              <KTextField v-model="values.name" field="roleName" required/>
              <MicroservicesAutocomplete v-model="values.microservices" field="microservices" hide-details multiple/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12"
               lg="6"
               md="8"
               offset-lg="3"
               offset-md="2"
               offset-sm="1"
               sm="10"
               v-if="permissionsFromSelectedMicroservices.length > 0">
          <v-subheader class="pa-0">{{ $t("role.permissions") }}</v-subheader>
          <v-card>
            <v-card-text>
              <v-row v-for="microservice in permissionsFromSelectedMicroservices" :key="microservice.microserviceId">
                <v-col>
                  <v-row no-gutters class="text-h6">{{ microservice.microserviceName }}</v-row>
                  <v-row no-gutters v-for="(permissionGroup, index) in microservice.permissionGroup" :key="index" class="align-end">
                    <v-col cols="4" class="v-label pb-1">
                      {{ permissionGroup.name }}
                    </v-col>
                    <v-col cols="4">
                      <VCheckbox v-model="values.permissions"
                                 v-if="permissionGroup.readPermissionId"
                                 :value="permissionGroup.readPermissionId"
                                 :label="$t('role.permissionType.read')"
                                 hide-details/>
                    </v-col>
                    <v-col cols="4">
                      <VCheckbox v-model="values.permissions"
                                 v-if="permissionGroup.allPermissionId"
                                 :value="permissionGroup.allPermissionId"
                                 :label="$t('role.permissionType.all')"
                                 hide-details/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </k-field-group>
    </v-form>
  </v-container>
</template>

<script lang="js">
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import Form from "@/components/vuetifyResource/VuetifyResourceBaseForm.vue";
import MicroservicesAutocomplete from "@/components/autocompletes/MicroservicesAutocomplete.vue";
import Role from "@/application/models/admin/role.js";
import { show } from "@/api/endpoints/admin/permission.js";

export default {
  name: "RoleForm",
  extends: Form,
  components: {
    MicroservicesAutocomplete,
    KTextField,
    KFieldGroup,
  },
  data: () => ({
    values: new Role(),
    permissions: [],
  }),
  computed: {
    permissionsFromSelectedMicroservices() {
      return this.permissions.filter(microservice => this.values.microservices.find(found => found.id === microservice.microserviceId));
    },
  },
  async created() {
    const response = await show();
    this.permissions = response.data.data;
  },
};
</script>
